<template>
  <div>
    <autosaveinfo :data="autoSave" @save="update" class="saveInfo" />

    <section class="form center">
      <h2>{{ $t('Profile.Info.personalInfo') }}</h2>
      <div class="row">
        <div>
          <label>{{ $t('Profile.Info.name') }}</label>
          <input v-model="temp_usr.usr_lastname" type="text" class="update" />
        </div>
        <div>
          <label>{{ $t('Profile.Info.fname') }}</label>
          <input v-model="temp_usr.usr_firstname" type="text" class="update" />
        </div>

      </div>

      <div class="row">

        <div>
          <label>{{ $t('Profile.Info.phone') }}</label>
          <input v-model="temp_usr.usr_phone" type="text" class="update" />
        </div>
      </div>
      <h2>{{ $t('Profile.Info.langSetting') }}</h2>
      <div>
        <label for="">{{ $t('Profile.Info.langChoice') }}</label>
        <select v-model="$i18n.locale" @change="setLang" class="update">
          <option value="en">{{ $t('Profile.languages.en') }}</option>
          <option value="fr">{{ $t('Profile.languages.fr') }}</option>
          <option value="es">{{ $t('Profile.languages.es') }}</option>
        </select>
      </div>


      <!--
      <div>
        <label>Entreprise</label>
        <input v-model="temp_usr.usr_company" type="text" />
      </div>

      <section class="inline">
        <div>
          <label>Rue</label>
          <input v-model="temp_usr.usr_address.street" type="text" />
        </div>
      </section>

      <section class="inline">
        <div>
          <label>Ville</label>
          <input v-model="temp_usr.usr_address.city" type="text" />
        </div>
        <div>
          <label>Code Postal</label>
          <input v-model="temp_usr.usr_address.postal_code" type="text" />
        </div>
      </section>

      <section class="inline">
        <div>
          <label>Departement</label>
          <input v-model="temp_usr.usr_address.department" type="text" />
        </div>
        <div>
          <label>Pays</label>
          <input v-model="temp_usr.usr_address.country" type="text" />
        </div>
      </section>
      -->
      <p class="button deleteAccount" button-type="delete" @click="deleteAccount">
        {{ $t('Profile.Info.deleteAccount') }}
      </p>
    </section>
  </div>
</template>

<script>

import axios from "axios";
import Vuex from "vuex";
import $ from "jquery";

//Components
import autosaveinfo from "../../../components/Object/AutoSaveInfo";

export default {
  data() {
    return {
      temp_usr: {
        usr_address: {},
      },
      autoSave: {
        status: 1,
        lastModification: Date,
        lastSave: "",
      },
    };
  },
  components: { autosaveinfo },
  mounted() {
    //Fetch all user videos
    this.temp_usr = this.getCurrentUser
    /*axios.get("/users/" + this.getCurrentUsrId).then((usr) => {
      this.temp_usr = Object.assign({}, this.temp_usr, usr.data);
    });*/
    $(".update").on("keyup", () => {
      this.autoSave.status = 0;
      this.autoSave.lastModification = new Date();

    });
    //AutoSave Interval
    setInterval(() => {
      var currentTime = new Date();
      var delta = (currentTime - this.autoSave.lastModification) / 1000;
      const saveDelay = 2;
      if (saveDelay == Math.round(delta)) {
        //SAVE
        console.debug("Saving data...");
        this.update();
        this.autoSave.lastSave = (() => {
          var date = new Date();
          return date.getHours() + ":" + date.getMinutes();
        })();
        this.autoSave.status = 1;
      }
    }, 1000);
  },

  computed: {
    ...Vuex.mapGetters(["getCurrentUsrId", "getCurrentUser"]),
  },

  watch: {
    "getCurrentUser": function (value) {
      this.temp_usr = value
    },
    "temp_usr.usr_phone": function (value) {
      if (value != undefined) {
        if (value.slice(0, 1) == "0" && value.length > 1) {
          this.temp_usr.usr_phone = "+33" + value.slice(1);
        }
        if (value.length > 12) {
          this.temp_usr.usr_phone = value.slice(0, 12);
        }
      }

    }/*,
    "temp_usr.usr_address.postal_code": function (value) {
      if (value.length > 5) {
        this.temp_usr.usr_address.postal_code = value.slice(0, 5);
      }
    },*/
  },
  methods: {
    update() {
      axios
        .put("/users/" + this.temp_usr._id, this.temp_usr)
        .then(() => {

          this.Toaster.success(this.$t('Toaster.success.update'))
        })
        .catch(() => {
          this.Toaster.error(this.$t('Toaster.error.update'))
        });
    },
    deleteAccount() {
      if (confirm(this.$t('Profile.Info.confirmDelete'))) {
        axios
          .delete("/users/" + this.temp_usr._id)
          .then(() => {
            this.$cookies.remove("token")
            this.$router.push({ name: "Login" });
            this.Toaster.success(this.$t('Profile.Info.success.deleteAccount'))
          })
          .catch((err) => {
            console.log(err)
            console.log(err.response)
            this.Toaster.error(this.$t('Profile.Info.error.deleteAccount'))
          });
      }
    },
    setLang(event) {
      this.$cookies.set("lang", event.target.value)
      localStorage.setItem("lang", event.target.value)
      this.temp_usr.usr_language = event.target.value
      this.$nextTick(() => {
        this.update()
      })
    }
  },
};
</script>



<style src="./style.css" scoped/>
